<template>
  <div id="app">
    <div class="shader" :class="{'visible': mainSearchFocused}"></div>
    <nav class="uk-navbar-container uk-padding-small uk-padding-remove-vertical" uk-navbar id="main_nav" uk-sticky>
      <div class="uk-navbar-left">
        <ul class="uk-navbar-nav uk-visible@m">
          <li><router-link :to="{name: 'Home'}" exact>HOME</router-link></li>
          <li><router-link :to="{ name: 'Wirkstoffe'}">WIRKSTOFFE</router-link></li>
          <li><router-link :to="{ name: 'Themen'}">THEMEN</router-link></li>
          <li><router-link :to="{ name: 'Quiz'}">QUIZ</router-link></li>
        </ul>

        <form @submit.prevent="initSearch" class="uk-hidden@m main_search_responsive_form" :class="{ 'focused': mainSearchFocused }">
          <div class="uk-inline">
              <label for="main_search_responsive" class="uk-icon-button uk-margin-small-right" uk-icon="search" @click="focusMainSearch"></label>
              <input id="main_search_responsive" ref="main_search_responsive" class="uk-input main_search_responsive" :class="{ 'focused': mainSearchFocused }" @focus="mainSearchFocused = true" @blur="mainSearchFocused = false" v-model="searchterm" type="text">
          </div>
        </form>
      </div>

      <div class="uk-navbar-center">
        <router-link :to="{name: 'Home'}" class="uk-text-lead font-lato anchor-home">PMU300</router-link>
      </div>
      <div class="uk-navbar-right">
        <form @submit.prevent="initSearch" class="uk-visible@m">
          <div class="uk-inline">
              <span class="uk-form-icon" uk-icon="icon: search"></span>
              <input id="main_search" ref="main_search" class="uk-input" :class="{ 'focused': mainSearchFocused }" @focus="mainSearchFocused = true; $event.target.select()" @blur="mainSearchFocused = false" v-model="searchterm" type="text">
          </div>
        </form>

        <ul class="uk-navbar-nav uk-hidden@m">
          <li><a class="uk-navbar-toggle" uk-toggle="target: #offcanvas-nav-primary" uk-navbar-toggle-icon></a></li>
        </ul>
      </div>
    </nav>

    <main class="uk-width-expand">
      <div id="viewport">
        <loader :hidden="appState === 'idle'"></loader>
        <router-view v-if="appState === 'idle'"></router-view>
      </div>
    </main>

    <footer class="uk-padding-large uk-text-center">
      <div class="uk-flex-center uk-child-width-1-3@s" uk-grid>
        <div class="uk-text-center">
          &nbsp;<br />
          <a href="https://www.pmu.ac.at/" target="_blank" ref="nofollow" class="uk-width-1-2 uk-text-left no-animate uk-display-inline-block"><img src="/pmu-logo-4c-rgb.svg" class="uk-display-block uk-margin-auto" /></a>
        </div>

        <div class="uk-text-center">
          <div>
            <a href="https://onlineted.de/de" target="_blank" ref="nofollow" class="uk-width-1-2 uk-text-left no-animate uk-display-inline-block">
              <span style="color: #83bb33;">powered by</span><br /><img src="/online-ted-education.jpg" class="uk-display-block uk-margin-auto" /></a>
          </div>
        </div>
      </div>

      <router-link id="link-imprint" class="uk-margin-medium-top uk-text-secondary uk-text-uppercase" :to="{name: 'Impressum'}">Impressum</router-link>
    </footer>

    <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true">
      <div class="uk-offcanvas-bar uk-flex uk-flex-column">
        <button class="uk-offcanvas-close uk-close-large uk-text-primary" type="button" uk-close></button>
        <ul class="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
          <li><router-link uk-toggle="target: #offcanvas-nav-primary" :to="{name: 'Home'}" exact>HOME</router-link></li>
          <li><router-link uk-toggle="target: #offcanvas-nav-primary" :to="{ name: 'Wirkstoffe'}">WIRKSTOFFE</router-link></li>
          <li><router-link uk-toggle="target: #offcanvas-nav-primary" :to="{ name: 'Themen'}">THEMEN</router-link></li>
          <li><router-link uk-toggle="target: #offcanvas-nav-primary" :to="{ name: 'Quiz'}">QUIZ</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import loader from './components/loader'
// eslint-disable-next-line
import UIKit from 'uikit'

export default {
  name: 'App',
  components: {
    loader
  },
  data: () => ({
    searchterm: '',
    mainSearchFocused: false
  }),
  mounted: function () {
    this.initializeApp()
  },
  methods: {
    initializeApp: function () {
      this.$store.dispatch('initializeApp')

      window.addEventListener('keyup', event => {
        if (event.ctrlKey === true && event.keyCode === 70) {
          this.focusMainSearch()
        }

        if (event.keyCode === 27) {
          this.blurMainSearch()
        }
      })
    },
    initSearch: function () {
      if (this.searchterm.length > 2) {
        this.$refs.main_search.blur()
        this.mainSearchFocused = false
        this.$router.push({ name: 'Suche', query: { t: this.searchterm } }).then(() => { this.$store.dispatch('clearSearch') }).catch(() => {})
      }
    },
    focusMainSearch: function () {
      this.mainSearchFocused = true
      this.$refs.main_search.focus()
      return false
    },
    blurMainSearch: function () {
      this.mainSearchFocused = false
      this.$refs.main_search.blur()
      return false
    }
  },
  computed: {
    ...mapState({
      appState: state => state.status
    })
  },
  watch: {}
}
</script>

<style lang="scss">
@import "./sass/uikit-theme.scss";

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#main_nav {
  background-color: #FFF;
  z-index: 980 !important;
}

form.main_search_responsive_form {
  label.uk-icon-button {
    background: none;
    cursor: pointer;
  }

  .uk-inline {
    transition: width 0.3s ease-in-out;
    span {
      cursor: pointer;
    }

    input {
      width: 0;
      border: 0;
    }
  }

  &.focused .uk-inline {
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 991;

    input {
      width: calc(100vw - 130px);
      @if(mixin-exists(hook-form-single-line)) {@include hook-form-single-line();}
      transition: width 0.3s ease-in-out;
    }
  }
}

#main_search,
#main_serach_responsive {
  &::-moz-selection { /* Code for Firefox */
    background: #2f68a847;
  }

  &::selection {
    background: #2f68a847;
  }
}

.shader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &.visible {
    transition: opacity 0.1s ease-in-out;
    opacity: 1;
    z-index: 950;
  }
}

.anchor-home {
  font-family: 'Lato';
  font-size: 2.1rem;
}

header {
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: block;
      position: relative;
      float: left;
      text-align: center;
      margin-right: 1rem;

      &::after {
        content: "|";
        margin-left: 1rem;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}

ul.blank {
  list-style: none;
  padding-left: 0;
  li {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

footer {
  background-color: #fff;
  color: $global-inverse-color;
  img {
    width: 75vw;

    @media (min-width: $breakpoint-small) {
      width: 50vw;
    }

    @media (min-width: $breakpoint-medium) {
      width: 30vw;
    }

    @media (min-width: $breakpoint-medium) {
      width: 25vw;
    }
  }
}
</style>
