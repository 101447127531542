/**
* Actions to manipulte vuex store
*/

import Axios from 'axios'
// import router from '@/router'

const setAppStatus = ({ dispatch, commit, state }, status) => {
  commit('SET_APP_STATUS', status)
}

const initializeApp = ({ dispatch, commit, state }) => {
  commit('SET_APP_STATUS', 'loading')
  const action1 = new Promise((resolve, reject) => {
    Axios
      .get('wirkstoffe')
      .then((response) => {
        commit('UPDATE_WIRKSTOFFE', response.data)
        resolve(response.data)
      })
      .catch((error) => {
        commit('SET_APP_STATUS', 'error')
        reject(error)
      })
  })

  const action2 = new Promise((resolve, reject) => {
    Axios
      .get('score')
      .then((response) => {
        commit('UPDATE_SCORE', response.data)
        resolve(response.data)
      })
      .catch((error) => {
        commit('SET_APP_STATUS', 'error')
        reject(error)
      })
  })

  const action3 = new Promise((resolve, reject) => {
    Axios
      .get('themen')
      .then((response) => {
        commit('UPDATE_THEMEN', response.data)
        resolve(response.data)
      })
      .catch((error) => {
        commit('SET_APP_STATUS', 'error')
        reject(error)
      })
  })

  return Promise.allSettled([action1, action2, action3]).then(() => {
    commit('SET_APP_STATUS', 'idle')
  }).catch(reason => {
  })
}

const clearSearch = ({ dispatch, commit, state }, term) => {
  commit('SET_SEARCH_RESULT', [])
}

const controller = new AbortController()

const search = ({ dispatch, commit, state }, term) => {
  if (term.length < 4) {
    return false
  }

  controller.abort()

  commit('SET_SEARCH_STATUS', 'loading')

  return new Promise((resolve, reject) => {
    Axios
      .get('search?t=' + term, {
        signal: controller.signal
      })
      .then((response) => {
        commit('SET_SEARCH_RESULT', response.data)
        commit('SET_SEARCH_STATUS', 'idle')
        resolve(response.data)
      })
      .catch((error) => {
        commit('SET_SEARCH_STATUS', 'error')
        reject(error)
      })
  })
}

const logRoute = ({ dispatch, commit, state }, route) => {
  if (route.name !== 'NotFound') {
    commit('LOG_ROUTE', route)
  }
}

const submitFeedback = ({ dispatch, commit, state }, feedback) => {
  return new Promise((resolve, reject) => {
    Axios
      .post('feedback',
        feedback
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const rateWirkstoff = ({ dispatch, commit, state }, params) => {
  return new Promise((resolve, reject) => {
    Axios
      .post('rating',
        params
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const countPage = ({ dispatch, commit, state }, id) => {
  Axios
    .post('countPage', {
      id: id // Wirkstoff-ID
    })
    .then((response) => {
      commit('UPDATE_VIEW_COUNT', { id: id, views: response.data.views })
    })
    // .catch((error) => {})
}

export default {
  setAppStatus,
  initializeApp,
  search,
  clearSearch,
  logRoute,
  submitFeedback,
  rateWirkstoff,
  countPage
}
