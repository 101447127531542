<template>
  <div>
    <header class="uk-position-relative uk-background-cover uk-flex uk-flex-center uk-flex-middle"  id="tile_search">
      <form class="search-home uk-text-center" @submit.prevent="initSearch">
        <h1 class="uk-margin-bottom-small">PMU300: DIE 300 WICHTIGSTEN ARZNEISTOFFE</h1>
        <h2 class="uk-margin-remove uk-margin-bottom uk-text-light">Paracelsus Medizinische Privatuniversität (PMU)</h2>
        <h2 class="uk-text-uppercase">Wirkstoffsuche</h2>
        <div class="uk-inline">
          <span class="uk-form-icon" uk-icon="icon: search"></span>
          <input class="uk-input uk-form-large uk-form-width-large@s" ref="focusMe" v-model="searchterm" type="text">
        </div>
      </form>
    </header>

    <div class="uk-container">
      <section class="uk-section uk-text-center">
        <p>Die Wirkstoffliste PMU300 gibt einen Überblick über die 300 wichtigsten Pharmaka für die Lehrveranstaltungen und Prüfungen des Instituts für Pharmakologie und Toxikologie der Paracelsus Medizinischen Privatuniversität (PMU) Salzburg.</p>
        <p>Unter Mitarbeit von Silvia Dossena, Emanuele Bernardinelli, Elisabeth Mooslechner, Felizian Kühbeck, Jill Weiler, Peter Amann, Bernadette Amann und Antonio Sarikas.</p>
        <p>Haftungsausschluss: Die Wirkstoffliste PMU300 dient ausschliesslich Lehrzwecken und ist nicht für den Einsatz in der Patientenversorgung konzipiert. Die Liste erhebt keinen Anspruch auf Vollständigkeit. Die Inhalte werden kontinuierlich auf der Basis von Nutzerfeedback bearbeitet und aktualisiert. </p>
        <hr class="uk-width-1-2 uk-margin-auto uk-margin-large-top">
      </section>

      <section class="uk-section uk-padding-remove-top" id="tile_score">
        <div class="uk-child-width-expand@s" uk-grid uk-height-match="target: > div > .uk-card">
          <!-- <div>
            <h2 class="uk-padding uk-padding-remove-vertical uk-text-light">PMU300 <span class="uk-text-bold">Ranking</span> <font-awesome-icon class="uk-text-small" icon="fa-solid fa-circle-info" uk-tooltip="pos: bottom; title: Der PMU300 <span class="uk-text-bold uk-text-uppercase">Score</span> ist eine evidenzbasierte Bewertung des Nutzen-Risiko-Profils von Arzneistoffen auf Grundlage der WHO Model List of Essential Medicines, des Arzneiverordnungs-Reports, Bewertungen des arznei-telegramms®, Empfehlungen medizinischer Leitlinien sowie Expertenmeinungen von Ärztinnen und Ärzten des Universitätsklinikums Salzburg. Weitere Informationen finden Sie hier (Link auf Abbildung).Der PMU300 <span class="uk-text-bold uk-text-uppercase">Score</span> wird durch das Institut für Pharmakologie und Toxikologie der PMU Salzburg jährlich ermittelt und aktualisiert. Die maximale Punktzahl ist 10, eine Aufnahme in die PMU300 Wirkstoffliste erfolgt ab 4 Punkten." /></h2>
            <div class="uk-card uk-card-default uk-card-body">
              <ul class="blank">
                <li v-for="(w, i) in wirkstoffe.slice(0, 5)" :key="i" class="uk-margin-bottom">
                  <router-link :to="{name: 'Wirkstoff', params: { 'wirkstoff': w.Wirkstoff }}">
                    <div class="uk-text-bold uk-padding-remove">Platz {{w.Ranking}}: {{w.Wirkstoff}}</div>
                    <p class="uk-margin-remove uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top">{{w.excerpt}} […] <span class="uk-text-bold">Mehr lesen</span></p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div> -->

          <div>
            <div class="uk-padding uk-text-center uk-padding-remove-vertical uk-margin-medium">
              <h2 class=" uk-text-light">PMU300 <span class="uk-text-bold uk-text-uppercase">Score</span></h2>
              <p>Der PMU300 <span class="uk-text-bold uk-text-uppercase">Score</span> ist ein vom Institut für Pharmakologie und Toxikologie der Paracelsus Medizinischen Privatuniversität entwickelter Punktewert zur evidenzbasierten Bewertung des Nutzen-Risiko-Profils von Arzneistoffen. Der PMU300 <span class="uk-text-bold uk-text-uppercase">Score</span> wird auf Grundlage der WHO Model List of Essential Medicines, des Arzneiverordnungs-Reports, Bewertungen des arznei-telegramms ®, Empfehlungen medizinischer Leitlinien sowie Expertenmeinungen von Ärztinnen und Ärzten des Universitätsklinikums Salzburg erstellt. Weitere Informationen finden Sie <router-link :to="{name: 'Score'}">hier.</router-link></p>
            </div>
            <div class="uk-card uk-card-default uk-card-body">
              <ul class="blank">
                <li v-for="(w, i) in score" :key="i" class="uk-margin-bottom">
                  <router-link :to="{name: 'Wirkstoff', params: { 'wirkstoff': w.Wirkstoff }}">
                    <div class="uk-text-bold uk-padding-remove">{{w.Score}} Punkte: {{w.Wirkstoff}}</div>
                    <p class="uk-margin-remove uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top">{{w.excerpt}} […] <span class="uk-text-bold">Mehr lesen</span></p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StartView',
  components: {},
  data () {
    return {
      searchterm: '',
      sortedWirkstoffe: []
    }
  },
  methods: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
    resizeTile: () => {
      document.getElementById('tile_search').style.height = (window.innerHeight - document.getElementById('main_nav').offsetHeight) + 'px'
    },
    initSearch: function () {
      if (this.searchterm.length > 2) {
        this.$store.dispatch('clearSearch')
        this.$router.push({ name: 'Suche', query: { t: this.searchterm } })
      }
    },
    focusInput: function () {
      this.$refs.focusMe.focus()
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.focusInput()
    })
    window.addEventListener('resize', this.resizeTile)
    this.resizeTile()
  },
  unmounted () {
    window.removeEventListener('resize', this.resizeTile)
  },
  computed: {
    ...mapState({
      wirkstoffe: state => state.wirkstoffe
    }),
    ...mapState({
      score: state => state.score
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../sass/variables-theme.scss";

  #tile_search {
    background: url('/public/background.jpg') no-repeat 50% 0;
    background-size: cover;
    max-height: 75vh;
    height: 75vh;

    h1 {
      color: #fff;
    }

    .uk-input {
      background: #FFF;
      color: $global-background;
    }
  }

  .uk-card {
    ul {
      padding: 0;
    }
  }

  #tile_score {
    ul li {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
</style>
